var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('loading-overlay',{attrs:{"active":_vm.apiStateLoading,"is-full-page":true,"loader":"bars"}}),_c('CCol',{attrs:{"sm":"12"}},[_c('CCollapse',{attrs:{"show":!_vm.collapse && !_vm.configCollapse}},[_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.yardsList')))])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"acciones",fn:function({ item }){return [_c('td',{staticClass:"text-center center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.toView')+' '+_vm.$t('label.yardSetting'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.toView')+' '+$t('label.yardSetting'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.viewYardConfig(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}}])})],1)],1)],1)],1)],1),_c('CCollapse',{attrs:{"show":_vm.configCollapse && !_vm.collapse}},[_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.selectedYardSettingList'))+": "+_vm._s(_vm.getCurrentYardName))])]),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"d-flex",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.return'),
                  placement: 'top',
                }),expression:"{\n                  content: $t('label.return'),\n                  placement: 'top',\n                }"}],staticClass:"to-start",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.returnYardList()}}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.yardSetting'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.yardSetting'),\n                  placement: 'top-end'\n                }"}],staticClass:"d-flex align-items-center to-end",attrs:{"color":"add"},on:{"click":function($event){return _vm.newYardConfig()}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1),_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedConfigItems,"fields":_vm.fieldsConfig,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell text-center"},[_c('div',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)])]}},{key:"acciones",fn:function({ item }){return [_c('td',{staticClass:"text-center center-cell actions-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.edit')+' '+_vm.$t('label.yardSetting'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.edit')+' '+$t('label.yardSetting'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.editConfig(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.toView') + ' ' +_vm.$t('label.observation'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.toView') + ' ' +$t('label.observation'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.getObservation(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.print'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.print'),\n                        placement: 'top-end'\n                      }"}],attrs:{"color":"print","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.printPdf(item)}}},[_c('CIcon',{attrs:{"name":"cil-print"}})],1),(item.YardConfigStatusId == 'CE1C43C0-5EDD-ED11-A88A-000D3A0DEDC7')?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: `${_vm.$t('label.Romeve')}`,
                        placement: 'top-start',
                      }),expression:"{\n                        content: `${$t('label.Romeve')}`,\n                        placement: 'top-start',\n                      }"}],staticClass:"ml-1",attrs:{"color":"wipe","size":"sm","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.deleteConfirmation(item)}}},[(!_vm.loading)?_c('CIcon',{attrs:{"name":"cil-trash"}}):_vm._e(),(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1):_vm._e()],1)]}}])})],1)],1)],1)],1)],1),_c('CCollapse',{attrs:{"show":_vm.collapse && !_vm.configCollapse}},[(_vm.collapse)?_c('div',[_c('Menu',{attrs:{"edit":_vm.edit},on:{"update:edit":function($event){_vm.edit=$event},"action":_vm.handleAction}})],1):_vm._e()]),_c('ObservationModal',{attrs:{"modal":_vm.observationModal,"observation":_vm.observation},on:{"update:modal":function($event){_vm.observationModal=$event}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }