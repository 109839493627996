<template>
  <CModalExtended
    :title="$t('label.legend')"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <CRow>
      <CCol sm="12" class="max-container">
        <div class="legend-container" v-if="currentItems.length != 0">
          <div
            v-for="(item, index) in currentItems"
            :key="item.YardSlotStatusId"
            class="legend-wrapper"
          >
            <div class="legend-icon">
              <div
                class="bg-position"
                :style="{ backgroundColor: colorPreference(item) }"
                v-if="hasColor(item)"
              ></div>
  
              <div :class="item.Class || null"></div>
            </div>
            <p
              class="legend-text"
            >
              {{ getYardSlotName(item) }}
            </p>
          </div>
        </div>
      </CCol>
    </CRow>         
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/>
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import { GenerateSvgPlaceholder, slugify } from '@/_helpers/funciones';
  import ENUM from '@/_store/enum';
  
  //Data
  function data() {
    return {
      loaded: false,
      modalActive: this.modal,
      items: [],
    }
  }
  
  //Methods
  function toggle(newVal) {
    if(newVal)
      this.getLegends();
    else
      this.items = [];
  
    this.modalActive = newVal;
  }
  function getLegends() {
    this.$store.state.yardsetting.apiState = ENUM.LOADING;
  
    let ruta = 'YardSlotConfigStatus-list';
  
    this.$http.ejecutar("GET", ruta)
      .then((response) => {
        this.items = [...response.data.data];
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.$store.state.yardsetting.apiState = ENUM.INIT;
      });
  }
  
  function hasColor(item) {
    return item.Color && item.FgColor;
  }
  function isFromPreference(item) {
    return item.Class ? false : true;
  }
  function slugName(name) {
    return slugify(name);
  }
  function colorPreference(item) {
    return this.hasColor(item) ? item.Color : null;
  }
  function checkIsOverSizeRight(item) {
    return item.Class == "oversize-right" ? true : null;
  }
  
  function hasLetter(item) {
    return item.Class && item.Class == 'letter';
  }
  function getLetter(item) {
    if(!this.hasLetter(item))
      return '';
  
    let index = item.Name.indexOf("(");
    if(index == -1)
      return '';
  
    return GenerateSvgPlaceholder({
      width: 40,
      height: 40,
      bgColor: 'transparent',
      textColor: '#3c4b64',
      fontSize: 30,
      fontWeight: '400',
      text: `${item.Name.slice(index+1, index+2)}`
    });
  }
  function getLetterName(item) {
    if(!this.hasLetter(item))
      return '';
  
    let index = item.Name.indexOf("(");
    if(index == -1)
      return '';
  
    return item.Name.slice(0, index-1);
  }

  function getYardSlotName(item) {
    return this.$i18n.locale == 'es' ? item.YardSlotStatusNameEs : item.YardSlotStatusNameEn
  }
  
  //Computed
  function currentItems() {
    return this.items.filter((item) => item.Class != null);
  }
  
  export default {
    name: 'yard-legend-modal',
    props: {
      modal: Boolean,
      areas: {
        type: Array,
        default: () => [],
      },
    },
    data,
    methods: {
      toggle,
      hasColor,
      isFromPreference,
      slugName,
      colorPreference,
      checkIsOverSizeRight,
      hasLetter,
      getLetter,
      getLetterName,
      getLegends,
      getYardSlotName,
    },
    computed: {
      currentItems,
      ...mapState({
        
      }),
    },
    watch: {
      modal: function(val) {
        this.toggle(val);
      },
      modalActive: function(val) {
        this.$emit('update:modal', val);
      }
    }
  }
  </script>
  
  <style scoped>
  /* Contenedor y cuadros */
  .max-container {
    height: 390px;
    max-height: 390px;
    overflow: auto;
    scrollbar-width: thin;
  }
  
  .legend-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  .legend-wrapper {
    margin: 5px;
    width: calc(calc(100% / 3) - 10px);
    display: flex;
    align-items: center;
  
    padding: 10px;
    /* border: 1px solid #000; */
  }
  .legend-icon {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border: 1px solid #000;
    position: relative;
  }
  .legend-text {
    margin-left: 10px;
    margin-top: 10px;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
  }
  .bg-position {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
    transition: background-color .5s ease-in-out;
  }
  
  /* Iconos */
  .flat-rack,
  .ft-flat-rack {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .plataforma,
  .pl-plataforma {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .tanque,
  .tk-tanque {
    width: 25%;
    height: 25%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin: 0 0 2px 2px;
  }
  .open-top,
  .ot-open-top {
    position: absolute;
    width: 100%;
    height: 30%;
    border: 1px solid #555;
    border-top: none;
    border-right: none;
    border-left: none;
    top: 0;
  }
  .standard,
  .st-estandar {
    background-image: url('/img/iconos/Standard.svg');
    position: absolute;
    width: 40%;
    height: 40%;
    top: 0;
    left: 0;
    margin-top: 2px;
    margin-left: 2px;
  }
  
  .imdg {
    position: absolute;
    width: 55%;
    height: 55%;
    top: 50%;
    left: 50%;
    border: 1px solid red;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    -o-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .high-cube,
  .hc-high-cube {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .high-cube-45,
  .footer-45 {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #373737;
  }
  .reefer-high-cube,
  .refrigerado-high-cube,
  .rt-refrigerado-high-cube  {
    background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
    background-position: left top, right bottom;
    background-size: 40% 40%, 40% 40%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height:100%;
  }
  .reefer {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 100% 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 100% 0 0 0;
    border-bottom: none;
    border-right: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .disabled-position {
  background-image: url('/img/yards/blocks/DISABLE-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.reefer-plug-status {
  background-image: url('/img/yards/blocks/RIFEL-PLUG-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hazard-available {
  background-image: url('/img/yards/blocks/HAZARD-AVAILABLE-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 35%;
  height: 35%;
  top: 0;
  right: 0;
}
.hazard-restriction {
  background-image: url('/img/yards/blocks/HAZARD-RESTRINGED-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 35%;
  height: 35%;
  top: 0;
  right: 0;
}
.special-container {
  background-image: url('/img/yards/blocks/SPECIAL-CONTAINER-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
  </style>