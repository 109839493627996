<template>
  <CModalExtended
    :title="currentTitle"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    class="modal-content-mov"
  >
    <form @submit.stop.prevent="submit" class="company-form">
      <CRow>
        <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow>
                <CCol sm="12"> 
                  <CInput
                    v-model.trim="$v.newStatus.ConfigName.$model"
                    :invalid-feedback="errorMessage($v.newStatus.ConfigName)"
                    :is-valid="hasError($v.newStatus.ConfigName)"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                    :label="$t('label.name')"
                    :placeholder="$t('label.name')"
                    :addLabelClasses="`text-right ${'required'}`"
                  >
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CTextarea
                    v-model.trim="$v.newStatus.ObservationReverse.$model"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                    :invalid-feedback="errorMessage($v.newStatus.ObservationReverse)"
                    :is-valid="hasError($v.newStatus.ObservationReverse)"
                    :label="$t('label.MOVIMIENTO.observation')"
                    :placeholder="$t('label.MOVIMIENTO.observation')"
                    addLabelClasses="text-right"
                    rows="4"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>         
    </form>
    <template #footer>
      <CButton
        color="add"
        class="d-flex align-items-center"
        :disabled="isSubmit"
        @click.stop="submit"
      >
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </CButton>
      <CButton
        color="wipe"
        :disabled="isSubmit"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
      </CButton>
    </template>
  </CModalExtended>
  </template>
  
  <script>
  import ChangeStatusValidation from '@/_validations/yard-setting/ChangeStatusValidation';
  import UpperCase  from '@/_validations/uppercase-directive';
  import ModalMixin from '@/_mixins/modal';
  import { mapState } from 'vuex';
  
  //Data
  function data() {
    return {
      modalActive: this.modal,
      isSubmit: false,
      newStatus: {
        ConfigName: '',
        ObservationReverse: ''
      },
      loading: false
    }
  }
  
  //Methods
  function submit() {
    try {
    this.isSubmit = true;
    this.loading = true;
    this.$v.newStatus.$touch();

    if (this.$v.newStatus.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let route = "YardConfig-insert";
    
    let YardConfigJson = {
      YardConfigId: this.isEdit ? this.yardConfigSelected?.YardConfigId : "", 
      YardId: this.yardSelected && this.yardSelected.YardId ? this.yardSelected.YardId : '', //Id del Patio a asignar la configuracion
      YardConfigName: this.newStatus.ConfigName,
      Observation: this.newStatus.ObservationReverse,
      ConfigDetailJson: this.layout.map((item) =>
        Object.assign({}, {
          YardConfigDetailId: this.isEdit ? item.YardConfigDetailId ? item.YardConfigDetailId : '' : "", //Caso Insert, enviar vacio 
          YardAreaId: item.YardAreaId ? item.YardAreaId : '', //Id del area del patio 
          YardBlockId: item.YardBlockId ? item.YardBlockId : '', //Id del  bloque
          x: item.x, //Posicion elemento en el eje x
          y: item.y, //Posicion elemento en el eje y
          h: item.h, //Posicion elemento altura
          w: item.w, //Posicion elemento ancho
          a: item.a,
          tpa: item.tpa,
          Location: item.Location, //Id de la region del svg.
          FgStatic: 0,
          Status: 1 ,
          FgBlockOrder: item.FgBlockOrder,
          FgBayOrder: item.FgBayOrder,
        })
      ),
      
      Status: 1 //Control del registro configuracion general del patio, caso insert 1
    };
    let metodo = "POST";

  this.$http
    .ejecutar(metodo, route, YardConfigJson, {
      root: "YardConfigJson",
    })
    .then(async (response) => {
      this.$notify({
        group: "container",
        title: "¡" + this.$t("label.success") + "!",
        text: response.data.data[0].Response,
        type: "success",
      });

      this.resetForm();
      this.modalActive = false;
      await setTimeout(() => {
        this.$emit('submited');
      }, 250);
    })
    .catch((err) => {
      this.isSubmit = false;
      this.modalActive = false;
      this.loading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isSubmit = false;
      this.loading = false;
    });

    } catch (e) {
      this.notifyError({ text: e });
      this.loading = false;
    }
  }
  
  function toggle(newVal) {
    if(!newVal)
      this.resetForm();
    else {
      if(this.isEdit){
        this.newStatus.ObservationReverse = this.yardConfigSelected.ObservationApproval;
        this.newStatus.ConfigName = this.yardConfigSelected.YardConfigName;
      }
    }
  
    this.modalActive = newVal;
  }
  function resetForm() {
    this.newStatus.ObservationReverse = '';
    this.$v.$reset();
  }
  
  //Computeds:
  function currentConfigName() {
    if(!this.yardConfigSelected) return '';

    return this.yardConfigSelected.YardConfigName;
  }
  function currentTitle() {
    return this.isEdit ? this.$t('label.editYardConfiguration'):this.$t('label.newYardConfiguration');
  }
  
  export default {
    name: 'new-settting-modal',
    mixins: [
      ModalMixin
    ],
    props: {
      modal: Boolean,
      isEdit: {
        type: Boolean,
        default: false,
      },
      yardSelected: {
        type: Object,
        default: {},
      },
      layout: {
        type: Array,
        default: [],
      },
    },
    data,
    validations() {
      return ChangeStatusValidation();
    },
    directives: UpperCase,
    methods: {
      submit,
      toggle,
      resetForm
    },
    computed: {
      currentConfigName,
      currentTitle,
      ...mapState({
        yardConfigSelected: state => state.yardsetting.yardConfigSelected,
      })
    },
    watch: {
      modal: function(val) {
        this.toggle(val);
      },
      modalActive: function(val) {
        this.$emit('update:modal', val);
      }
    }
  }
  </script>
  <style lang="scss">
  .card-simple{
    border: 0px;
    margin-bottom: 0 !important;
  }
  .modal-content-mov {
    .modal-content {
      width: 70% !important;
      margin: auto !important;
      .modal-body {
        padding-left: 1.7rem;
        padding-right: 1.7rem;
      }
    }
  }
  </style>